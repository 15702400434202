import React, { useState, useEffect, createContext } from 'react'
import { getUser } from '../utils/authUtils'

const AuthContext = createContext()

const AuthProvider = ({ children }) =>  {
  const [user, setUser] = useState({})
  const [isFetching, setIsFetching] = useState(true)
  const [error, setError] = useState(null)

  const getUserDetails = async () => {
    try {
      const userDetails = await getUser()
      setUser(userDetails)
    } catch (error) {
      console.log('error', error)
    }

    setIsFetching(false)
  }

  useEffect(() => {
    getUserDetails()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        isFetching,
        setError,
        error
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext

export { AuthProvider }