import Amplify from 'aws-amplify'

Amplify.configure({
  Auth: {
    identityPoolId: process.env.GATSBY_COGNITO_IDENTITY_POOL_ID,
    region: process.env.GATSBY_COGNITO_REGION,
    userPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_COGNITO_USER_POOL_CLIENT_ID
  },
  Storage: {
    AWSS3: {
      bucket: process.env.GATSBY_CDN_BUCKET,
      region: process.env.GATSBY_INFRA_REGION
    }
  }
})