import { Auth, graphqlOperation } from 'aws-amplify'
import axios from 'axios'

const getUserPermissions = async idToken => {
  const query = /* GraphQL */ `
    query getCurrentUser {
      getCurrentUser {
        userName
        permissions {
          id
          operationType
          resourceId
          resourceType
        }
      }
    }
  `

  const operation = graphqlOperation(query, {})
  const apiUrl = process.env.GATSBY_API_DOMAIN_NAME

  try {
    const response = await axios({
      url: `${apiUrl}/management/graphql`,
      method: 'POST',
      data: operation,
      headers: {
        authorization: idToken
      }
    })

    const { data } = response
    const { userName, permissions } = data?.data?.getCurrentUser

    const docsPermissions = permissions.findIndex(
      ({ resourceType, operationType }) => {
        return resourceType === 'docs' && operationType === 'read'
      }
    )

    const permissionsArray = docsPermissions > -1 ? ['development'] : []

    const user = {
      username: userName,
      permissions: permissionsArray,
      idToken
    }

    return user
  } catch (error) {
    console.log('Error fetching permissions: ', error)
  }

  return {}
}

export const signIn = async (username, password) => {
  const cognitoUser = await Auth.signIn(username, password)
  const idToken = cognitoUser.signInUserSession.idToken.jwtToken
  return await getUserPermissions(idToken)
}

export const signOut = async () => {
  return await Auth.signOut()
}

export const getUser = async () => {
  let user = {}
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser()
    const idToken = cognitoUser.signInUserSession.idToken.jwtToken
    const user = await getUserPermissions(idToken)
    return user
  } catch (error) {
    console.log('Error fetching Cognito user: ', error)
  }

  return user
}

export const restrictedSections = ['development']
