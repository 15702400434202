import React from 'react'
import theme from 'prismjs/themes/prism.css'
import '@fontsource/inter'
import '@fontsource/inter/600.css'

import { AuthProvider } from './src/context/AuthContext'

export const wrapRootElement = ({ element }) => {
  return (
    <AuthProvider>
      {element}
    </AuthProvider>
  )
}